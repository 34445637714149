import React from 'react';
import SocialMedia from '../SocialMedia';
import imgLogo from '../../../assets/img/logo-light.png';

import Styles from './footer.module.scss';

export default function Footer() {
  return (
    <div className={Styles.footerWrapper}>
      {/* <JoyStickLogo className={Styles.logo} /> */}
      <img className={Styles.logo} src={imgLogo} alt="logo" />
      <SocialMedia />
    </div>
  );
}
