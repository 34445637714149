import {
  MetaMaskIcon,
  ConnectWalletIcon,
  CloseIconRounded,
} from '../../../components/icons';
import Modal from '../../../components/Modal';
import { useWeb3Provider } from '../../../hooks';
import Prohibition from '../../../assets/img/prohibition.png';
import ChevronDownImg from '../../assets/img/chevron-down.svg';

import Styles from './ApplyPanel.module.scss';
import React from 'react';
import { getWalletAddressAbbr } from '../../../utils';
import { useNavigate } from 'react-router-dom';

type ApplyPanelProps = {
  showInfo: boolean;
  setShowInfo: React.Dispatch<React.SetStateAction<boolean>>;
};

const ApplyPanel: React.FC<ApplyPanelProps> = (props) => {
  const navigate = useNavigate();

  const { account, active, deactivate, activate } = useWeb3Provider();

  return (
    <>
      <div className={Styles.applyPanel}>
        <div className={Styles.applyJoystick}>Apply to Joystick</div>
        <div className={Styles.buttonWrapper}>
          {/* metamask button */}

          {active ? (
            <>
              
              <div
                className={`${Styles.button} ${Styles.primary}`}
                onClick={() => {
                  localStorage.clear();
                  // localStorage.setItem('loggedIn', '0');
                  navigate("/", { replace: true })
                  deactivate();
                }}
              >
                <div className={Styles.disconnect}>
                <span>{getWalletAddressAbbr(account)}</span>
                <div>Disconnect</div>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${Styles.button} ${Styles.primary}`}
              onClick={() => activate('Injected')}
            >
              <div className={Styles.icon}>
                <MetaMaskIcon className={Styles.icon} />
              </div>
              <div className={Styles.buttonText}>MetaMask</div>
            </div>
          )}

          {/* wallet connect button */}
          <div
            className={`${Styles.button} ${Styles.secondary}`}
            onClick={() => activate('WalletConnect')}
          >
            <div className={Styles.icon}>
              <ConnectWalletIcon key={1} className={Styles.icon} />
            </div>
            <div className={Styles.buttonText}>WalletConnect</div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={props.showInfo}
        onRequestClose={() => {
          props.setShowInfo(false);
        }}
        style={{
          overlay: {
            background: 'rgba(6, 14, 33, 0.72)',
          },
          content: {
            maxWidth: '410px',
            width: '100%',
            background: '#fff',
            borderRadius: '16px',
          },
        }}
      >
        <div className={Styles.prohibitionPopUp}>
          <CloseIconRounded
            className={Styles.closeIcon}
            onClick={() => {
              props.setShowInfo(false);
            }}
          />

          <img src={Prohibition} alt="prohibition" />

          <p className={Styles.tokenNotFoundText}>
            It looks like you don't have any $JOY/$xJOY token in your wallet.
          </p>
          <p className={Styles.tokenMessage}>
            Please connect another wallet or purchase $JOY/$xJOY tokens to proceed
            with your application
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ApplyPanel;
