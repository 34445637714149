import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatUnits } from 'ethers/lib/utils';

import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import ApplyPanel from './components/ApplyPanel';

import { useWeb3Listener, useWeb3Provider } from '../../hooks';
import AfterTransactionModal from './components/AfterTransactionModal';
import { ASSET_LIST } from '../../constants/contracts';
import { useContract, useIsMounted } from '../../hooks';
import { BigNumber } from 'ethers';

import Styles from './apply.module.scss';

const ApplyLogin = () => {
  const navigate = useNavigate();
  useWeb3Listener();
  const { account, active } = useWeb3Provider();

  const [showInfo, setShowInfo] = useState(false);
  const xJoyContract = useContract(
    ASSET_LIST['xJOY'].address,
    ASSET_LIST['xJOY'].abi,
    true
  );
  const joyContract = useContract(
    ASSET_LIST['JOY'].address,
    ASSET_LIST['JOY'].abi,
    true
  );

  useEffect(() => {
    setShowInfo(false);
    // localStorage.clear();
  }, []);
  const isMounted = useIsMounted();
  useEffect(() => {
    if (!active) {
      setShowInfo(false);
    }
  }, [account, active]);

  useEffect(() => {
    // if (localStorage.getItem('loggedIn') === '1') return;
      if (active && account && xJoyContract && joyContract && isMounted.current) {
        //
        Promise.all([xJoyContract.balanceOf(account), joyContract.balanceOf(account)])
        .then(
          ([xJoyValueBN, joyValueBN]) => {
            const xJoyValue = parseFloat(formatUnits(xJoyValueBN.toString(), 18));
            const joyValue = parseFloat(formatUnits(joyValueBN.toString(), 18));
            if (xJoyValue > 0 || joyValue > 0) {
              localStorage.setItem('loggedIn', '1');
              navigate('/question', { replace: true });
            } else {
              setShowInfo(true);
            }            
          })
          .catch((error: any) => {
            console.error(error);
            setShowInfo(true);            
          })
      }
    // eslint-disable-next-line
  }, [account, active]);

  return (
    <div className={Styles.applyPage}>
      <Header />

     <div>
     <ApplyPanel showInfo={showInfo} setShowInfo={setShowInfo} />
     
     </div>

      

      <AfterTransactionModal />

      <Footer />
    </div>
  );
};

export default ApplyLogin;
