import React from 'react';

import { Discord, Medium, Twitter, GitBook, Telegram } from '../../icons';
import iconInstagram from '../../../assets/img/icon-instagram.svg';
import iconTiktok from '../../../assets/img/icon-tiktok.svg';
import GitBookIcon from '../../../assets/img/icon-gitbook.svg';
import Styles from './social-media.module.scss';

type SocialMediaProps = {
  className?: string;
};

export default function SocialMedia({ className = '' }: SocialMediaProps) {
  return (
    <>
      <div className={`${Styles.socialIcons} ${className}`}>
      <a
          href="https://t.me/joystickgaming"
          target="_blank"
          rel="noreferrer"
        >
          <Telegram className={Styles.icon} />
        </a>
        <a
          href="https://twitter.com/joystickpros"
          target="_blank"
          rel="noreferrer"
        >
          <Twitter className={Styles.icon} />
        </a>
        <a
          href="https://discord.gg/joystickgaming"
          target="_blank"
          rel="noreferrer"
        >
          <Discord className={Styles.icon} />
        </a>

        {/* <a
          href="https://joystick-games.medium.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Medium className={Styles.icon} />
        </a> */}

        <a
          href="https://www.instagram.com/joystick/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={iconInstagram} className={Styles.icon} alt="" />
        </a>

        <a
          href="https://www.tiktok.com/@joystick"
          target="_blank"
          rel="noreferrer"
        >
          <div className={Styles.icon} style={{ position: 'relative', backgroundColor: '#fff', backgroundImage: `url(${iconTiktok})`, backgroundSize: 16, backgroundPosition: 'center center', backgroundRepeat: 'no-repeat', borderRadius: '50%' }} />
        </a>

        {/* <a
          href="http://whitepaper.joystickgaming.io/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={GitBookIcon} className={Styles.icon} alt="" />
        </a> */}
      </div>
    </>
  );
}
