import { http } from './api'
import { SALE_TYPE } from '../constants'
class TrackingService {
  async checkPassword (password: string) {
    const type = Number(SALE_TYPE);
    const res = await http.get<any>(`/api/user/password/${type}/${password}`);
    return res.data;
  }
  async createTracking (password: string, wallet: string, hash: string, amount: number, tokens: number) {
    const type = Number(SALE_TYPE);
    const payload = {
      password, wallet, hash, amount, tokens, type
    }
    const res = await http.post<any>(`/api/user/tracking`, payload);
    return res.data;
  }
}

export const trackingService = new TrackingService()
