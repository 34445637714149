import './PresaleHeader.scss';

import { useContext, useState, useLayoutEffect, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../assets/img/logo-light.png';
import HeaderLogo from '../assets/img/presale-banner-logo.png';
import ChevronDownImg from '../assets/img/chevron-down.svg';
import { UserContext } from '../contexts/UserContext';
import { useWeb3Provider } from '../hooks';
import { getWalletAddressAbbr } from '../utils';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Header = () => {
  const navigate = useNavigate();
  const { isWalletConnectOpened, setIsWalletConnectOpened } =
    useContext(UserContext);
  const { account, active, deactivate } = useWeb3Provider();
  const [isWalletInfoOpened, setIsWalletInfoOpened] = useState(false);
  const [width, height] = useWindowSize();
  const [headerStyle, setHeaderStyle] = useState({});

  useEffect(() => {
    if (window.location.pathname !== "/" && width <= 768) {
      setHeaderStyle({height: '130px', backgroundImage: 'none'})
    } else {
      setHeaderStyle({})
    }
  }, [width, height])

  return (
    <div 
      className="presale-header"
      style={headerStyle}
    >
      <div className="presale-header-container">
        <img className="presale-logo" src={Logo} alt="logo header" />
        <button
          className="presale-wallet-btn"
          onClick={() => {
            if (active) {
              setIsWalletInfoOpened(!isWalletInfoOpened);
            } else {
              !isWalletConnectOpened
                ? setIsWalletConnectOpened(true)
                : setIsWalletConnectOpened(false);
            }
          }}
        >
          {active ? (
            <>
              <span>{getWalletAddressAbbr(account)}</span>
              <img src={ChevronDownImg} alt="" />
            </>
          ) : (
            'Connect Wallet'
          )}
        </button>

        {active && isWalletInfoOpened ? (
          <button
            className="wallet-info-button"
            onClick={() => {
              localStorage.clear();
              // localStorage.setItem('loggedIn', '0');
              navigate("/", { replace: true })
              deactivate();
              setIsWalletInfoOpened(false);
            }}
          >
            Disconnect
          </button>
        ) : null}
      </div>
    </div>
  );
};
export default Header;
