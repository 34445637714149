import './App.scss';

import ReactModal from 'react-modal';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ApplyLogin from './pages/ApplyLogin';
import ApplyQuestion from './pages/ApplyQuestion';

ReactModal.setAppElement('#root');

const App = () => {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/question" element={<ApplyQuestion />} />
          <Route path="/" element={<ApplyLogin />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </>
  );
};

export default App;
