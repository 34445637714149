import React from 'react';
import { CloseIconMobileNav, MobileNavIcon } from '../../icons';
import imgLogo from '../../../assets/img/logo-light.png';

import Styles from './header.module.scss';

export default function Header() {
  const [show, setShow] = React.useState<boolean>(true);

  /******************** METHODS *************************/
  const toggleMobNav = () => {
    setShow(prev => !prev)
  };
  return (
    <header className={Styles.header}>
      <div className={Styles.desktop}>
        <img src={imgLogo} className={Styles.logo} alt="logo" />
    
      </div>

      <div className={Styles.mobile}>
        <div className={Styles.mobileNavWrapper}>
          <img src={imgLogo} className={Styles.logo} alt="logo" />
          {show ? <CloseIconMobileNav onClick={toggleMobNav} /> : <MobileNavIcon onClick={toggleMobNav} />}
        </div>
     
      </div>
    </header>
  );
}
