import React from 'react';
import './ApplyPanel.scss';

const ApplyPanel = () => {
  return (
    <div className="apply-panel" id="none-scrollbar">
      <div id="iframe-wrapper">
        <iframe
          id="joyIframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLScnk6j_dmYZil36NiVRF5vrrkzDyNjXTcy4MGlOnRq7ZmtzBA/viewform?embedded=true"
          frameBorder="0"
          title="Rental form"
        >Loading…</iframe>
      </div>
    </div>
  );
};

export default ApplyPanel;
