import './Apply.scss';

import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/PresaleHeader';

import ConnectModal from '../../components/ConnectModal';
import ApplyPanel from './components/ApplyPanel';
import { UserContext } from '../../contexts/UserContext';
import { useWeb3Listener, useERC20Balance, useWeb3Provider, useEagerConnect } from '../../hooks';
import AfterTransactionModal from './components/AfterTransactionModal';
import { ASSET_LIST } from '../../constants/contracts';
import { useContract, useIsMounted } from '../../hooks';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';

const ApplyQuestion = () => {
  const navigate = useNavigate();
  useEagerConnect();
  useWeb3Listener();
  const { account, active, deactivate } = useWeb3Provider();
  const { isWalletConnectOpened, setIsWalletConnectOpened } = useContext(UserContext);
  const isMounted = useIsMounted();
  const erc20Contract = useContract(ASSET_LIST['xJOY'].address, ASSET_LIST['xJOY'].abi, true);
  const [oldActive, setOldActive] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('loggedIn') !== '1') {
      navigate("/", { replace: true })
    }
  }, [])

  useEffect(() => {
    if (active) {
      if (account && erc20Contract) {
        erc20Contract
          .balanceOf(account)
          .then((value: BigNumber) => {
            if (isMounted.current) {
              const result = parseFloat(
                formatUnits(value.toString(), 18)
              );
              if (result === 0) {
                // localStorage.clear();
                navigate("/", { replace: false })
              }

            }
          })
          .catch((error: any) => {
            if (isMounted.current) {
              // localStorage.clear();
              // navigate("/", { replace: true })
            }
          });
      }
    } else {
      // navigate("/", { replace: true })
    }
  }, [account, active]);

  useEffect(() => {
    if (oldActive && !active) {
      navigate("/", { replace: true })
    }
    setOldActive(active);
  }, [active]);
  return (
    <div className="apply-page">
      <div className="apply-page-wrapper">
        <Header />
        <ApplyPanel />

        <ConnectModal
          isOpened={isWalletConnectOpened}
          onClose={() => setIsWalletConnectOpened(false)}
        />

        <AfterTransactionModal />
      </div>
    </div>
  );
};

export default ApplyQuestion;